<template>
	<div class="page-container page_regi">
		<RegiHead />
		<div class="regi-bd change_bd">
			<div class="regi_name">{{$t('acTxt9')}}</div>
			<el-form ref="form" :rules="rules" class="regi-from" :model="form">
				<el-form-item prop="oldPassword">
					<div class="regi_group">
						<div class="regi_gr_t">{{$t('chTxt4')}}</div>
						<div class="regi_gr_b">
							<el-input v-model="form.oldPassword" placeholder=""  :type="pass?'password' : 'text'">
							</el-input>
							<div class="regi_eye" @click="eye">
								<i class="iconfont icon-eye-close" v-if="Eye"></i>
								<i class="iconfont icon-eye" v-else></i>
							</div>
						</div>
					</div>
				</el-form-item>
				<el-form-item prop="newPassword">
					<div class="regi_group">
						<div class="regi_gr_t">{{$t('chTxt5')}}</div>
						<div class="regi_gr_b">
							<el-input v-model="form.newPassword" placeholder="" :type="pass2?'password' : 'text'">
							</el-input>
							<div class="regi_eye" @click="eye2">
								<i class="iconfont icon-eye-close" v-if="Eye2"></i>
								<i class="iconfont icon-eye" v-else></i>
							</div>
						</div>
					</div>
				</el-form-item>
				<el-form-item prop="checkPassword">
					<div class="regi_group">
						<div class="regi_gr_t">{{$t('chTxt6')}}</div>
						<div class="regi_gr_b">
							<el-input v-model="form.checkPassword" placeholder="" :type="pass3?'password' : 'text'">
							</el-input>
							<div class="regi_eye" @click="eye3">
								<i class="iconfont icon-eye-close" v-if="Eye3"></i>
								<i class="iconfont icon-eye" v-else></i>
							</div>
						</div>
					</div>
				</el-form-item>
				<el-form-item>
					<div class="regi_group chan_btn flex flex-ac flex-between">
						<el-button class="btn btn_reset" type="primary" @click="resetForm('form')">{{$t('reset')}}
						</el-button>
						<el-button class="btn" type="primary" @click="submitForm('form')">{{$t('save')}}</el-button>
					</div>
				</el-form-item>
				<div class="change_forget">
					<router-link to="/retrieveFund">{{$t('chTxt7')}}?</router-link>
				</div>
			</el-form>
			<div class="regi_tips">Copyright ©2024 Alcex All rights reserved.</div>
		</div>
	</div>
</template>

<script>
	import {
		transactionUpdatePass
	} from '@/api/api/user'
	import RegiHead from '@/components/RegiHead.vue'
	export default {
		components: {
			RegiHead,
		},
		data() {
			var validatePass = (rule, value, callback) => {
				if (value === "") {
					callback(new Error(this.$t("chtip6")));
				} else if (!/([a-zA-Z0-9]){6,18}/.test(value)) {
					callback(new Error(this.$t("chtip7")));
				} else {
					callback();
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if (value === "") {
					callback(new Error(this.$t("chtip7")));
				} else if (!/([a-zA-Z0-9]){6,18}/.test(value)) {
					callback(new Error(this.$t("chtip7")));
				} else if (value !== this.form.newPassword) {
					callback(new Error(this.$t("chtip7")));
				} else {
					callback();
				}
			};
			return {
				form: {},
				rules: {
					oldPassword: [{
						required: true,
						type: "string",
						min: 6,
						message: this.$t('chtip'),
						trigger: 'blur'
					}],
					newPassword: [{
						required: true,
						type: "string",
						min: 6,
						message: this.$t("chtip6"),
						trigger: "blur"
					}, {
						validator: validatePass,
						trigger: 'blur'
					}],
					checkPassword: [{
						required: true,
						type: "string",
						min: 6,
						message: this.$t("chtip7"),
						trigger: "blur"
					}, {
						validator: validatePass2,
						trigger: 'blur'
					}],
					Eye: true,
					Eye2: true,
					Eye3: true,
					pass: true,
					pass2: true,
					pass3: true,
				}
			};
		},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						transactionUpdatePass({
							newPassword: this.form.newPassword,
							oldPassword: this.form.oldPassword
						}).then(res => {
							if (res.code == 0) {
								this.$message.success(this.$t('save_success'));
								this.$router.push('/admin')
							} else {
								this.$message.error(res.message);
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			eye() {
				this.Eye = !this.Eye
				this.pass = !this.pass
			},
			eye2() {
				this.Eye2 = !this.Eye2
				this.pass2 = !this.pass2
			},
			eye3() {
				this.Eye3 = !this.Eye3
				this.pass3 = !this.pass3
			}
		}
	}
</script>
